jQuery(document).ready(function ($) {
    //wrap the right section, and find our target
    $('.shipping_address', $('.woocommerce-shipping-fields')).prevAll().addBack().wrapAll('<div class="woocommerce-shipping-fields-wrapper"></div>');
    var $shipping_div = $('.woocommerce-shipping-fields-wrapper');

    //bind the event
    $(document.body).on('updated_checkout', function (e) {

        //find the currently selected shippping method
        var shipping_method = $('.shipping_method:checked').val();

        if (!shipping_method) {
            shipping_method = $('.shipping_method option:selected').val();
        }

        var parentToggler = $('.parent-toggler');

        if( parentToggler ) {
            // If checkbox 'ship to different address' is checked, hide the local pickup options.
            if ($('input[name="ship_to_different_address"]:checked').length > 0) {
                parentToggler.hide();   // go hide
            } else {
                parentToggler.show();
            }
        }

        // Exectute this only if a shipping method is set.
        if (shipping_method) {
            //does it have our shipping-method name in it?
            var index = shipping_method.search('yoast_wcseo_local_pickup_');

            if (index > -1) {
                $shipping_div.hide();   // go hide
            } else {
                $shipping_div.show();   // show it
            }
        }
    });
});